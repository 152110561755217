import { Alert, AlertProps } from 'components/Alert';
import BackBtn from 'components/BackBtn';
import { useFormik } from 'formik';
import { MerchantCountry } from 'lib/types';
import React, { useCallback, useMemo, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { setContactDetails } from 'redux/checkoutSlice';
import { ReduxState } from 'redux/reduxTypes';
import * as Yup from 'yup';

/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Heading, Text, TextField } from '@limepayments/cosmic';

import { convertToE164Format } from './utils';

const PhoneField = React.forwardRef<HTMLInputElement>((props, ref) => (
  <TextField testId="phoneInput" inputRef={ref} label="Mobile number" fullWidth {...props} />
));

type FormValues = {
  phone: string;
  email: string;
};

export type ContactDetailsStepProps = {
  onBack?(): void;
  onSubmit?(values: FormValues): void;
};

export const ContactDetailsStep = ({ onBack, onSubmit }: ContactDetailsStepProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const { params, config, currentUser, contactDetailsEmail, contactDetailsPhone } = useSelector(
    (state: ReduxState) => ({
      params: state.params,
      config: state.config,
      currentUser: state.currentUser,
      contactDetailsEmail: state.contactDetailsEmail,
      contactDetailsPhone: state.contactDetailsPhone,
    }),
  );

  const showPhone = !config?.isB2B;

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async ({ phone, email }: FormValues) => {
      dispatch(setContactDetails({ phone, email }));

      setAlert(null);
      setIsLoading(true);

      const alert = await onSubmit?.({ phone, email });

      setAlert(alert ?? null);
      setIsLoading(false);
    },
    [dispatch, onSubmit],
  );

  const initialValues: FormValues = useMemo(() => {
    const { _phone: phone } = convertToE164Format(
      contactDetailsPhone || currentUser?.phoneNumber || params?.phone || '',
      config?.merchantTradingCountry ?? MerchantCountry.AU,
    );

    const email = contactDetailsEmail || currentUser?.email || params?.email || '';

    return { phone, email };
  }, [params, config, currentUser, contactDetailsEmail, contactDetailsPhone]);

  const form = useFormik<FormValues>({
    initialValues,
    validationSchema: Yup.object({
      phone: showPhone
        ? Yup.string()
            .required('Mobile number is required')
            .test('Phone', 'Mobile number is invalid', (value) => isPossiblePhoneNumber(value))
        : Yup.string().notRequired(),
      email: Yup.string().email('Email address is invalid').required('Email address is required'),
    }),
    onSubmit: handleSubmit,
  });

  const { touched, values, errors, handleChange, handleBlur, setFieldValue } = form;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        fontFamily: 'var(--lp-fonts-body-2)',
        color: 'rgb(var(--lp-colors-neutral-700))',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <BackBtn onClick={onBack} />
        <Heading tagName="h2" variant="xs">
          Verify your contact details
        </Heading>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
            fontSize: 14,
            color: 'rgb(var(--lp-colors-neutral-600))',
            backgroundColor: 'rgb(var(--lp-colors-neutral-white))',
            border: '1px solid rgb(var(--lp-colors-neutral-400))',
            borderRadius: 4,
          }}
        >
          <Text variant="body-3">
            Help us confirm your identity, by verifying your{showPhone ? ' mobile number and ' : ' '}email.
          </Text>
          {showPhone && (
            <PhoneInput
              css={{ '.PhoneInputCountry': { display: 'none' } }}
              inputComponent={PhoneField}
              name="phone"
              type="tel"
              defaultCountry={config?.merchantTradingCountry}
              international
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value ?? '')}
              onBlur={handleBlur}
              error={touched.phone ? errors.phone : ''}
              disabled={currentUser?.isVerified}
              required
              autoComplete="off"
            />
          )}
          <TextField
            testId="emailInput"
            name="email"
            type="email"
            label="Email address"
            fullWidth
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email ? errors.email : ''}
            required
            autoComplete="off"
          />
          {!!alert && <Alert {...alert} />}
          <div css={{ zIndex: 1 }}>
            <Button
              testId="submitBtn"
              size="large"
              variant="primary"
              className="lp-w-full"
              onClick={form.submitForm}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
